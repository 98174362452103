*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

#body{
  min-height: 30vh;
  min-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
}

.header{
  background-color: #f5f5f7;
  color: black;
  width: 100%;
  text-align: center;
  height: 170px;
  padding: 30px ;
}

.header-title{
  font-size: 50px;
}

.header-text{
  font-size: 20px;
}

.footer {
  background-color: #f5f5f7;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.footer-title{
  font-size: 30px;
  height: 30%;
}

.footer-text{
  font-size: 20px;
  height: 70%;
}

.div-title {
  font-size: 30px;
  font-weight: 600;
  /*text-align: center;*/
}
.div-description {
  text-align: left;
}

.card_container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 95%;
  /*height: 800px;*/
  padding: 10px; /*space from top margin*/
}

.card-row-container {
  display: flex;
  flex-direction: row;
  /*flex-wrap: wrap;*/
  justify-content: space-evenly;
  padding: 10px; /*space between rows*/
  width: 100%;
}

.card-row-left-image-container {
  /*display: flex;
  flex-direction: column;*/
  /*justify-content: left;*/ /*this actually means top because it is a column*/
  width: 30%; /*% of width image should be, will max at 250px though*/
  max-width: 250px; /*don't let image get bigger than this*/
}
.card-row-right-container-column {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  padding: 10px; /* space between left image and right text*/
  width: 70%;
}


.aspect-fit-image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}